import {StoryblokComponent, getStoryblokApi} from '@storyblok/react'
import React, {useEffect, useState} from 'react'
import MainMenuHeader from './Navigation/MainMenuHeader'

import classNames from 'classnames'
import {pageTypes} from 'pages/[[...slug]]'
import {TranslatedSlug} from 'types/types'
import {MainMenuSlugAlternates} from 'mainMenuSlugTranslations'
import analytics from 'analytics'
import {useSession} from 'next-auth/react'
import {useCustomerAffiliateContext} from 'context/CustomerAffiliateProvider'
import {useRouter} from 'next/router'
import AuthError from 'components/AuthError'
import AlertContainer from './Alert'
import Maintenance from './Maintenance'
import {
  AlertStoryblok,
  MainMenuStoryblok,
  MaintenancePageStoryblok,
} from 'types/storyblok-types'

type Layout = {
  children?: React.ReactNode
  mainMenuSlugAlternates: MainMenuSlugAlternates
  defaultSlug?: string
  translatedSlugs?: TranslatedSlug[]
} & Pick<pageTypes, 'footer' | 'mainMenu' | 'errorPage' | 'authPage'>

const Layout = ({
  children,
  mainMenu,
  mainMenuSlugAlternates,
  defaultSlug,
  translatedSlugs,
  footer,
  authPage,
}: Layout) => {
  // Tracks whether the full main menu is visible on mobile screens.
  const [isMainMenuVisible, setIsMainMenuVisible] = useState<Boolean>(false)

  const mainClassNames = classNames(
    'col-span-8 p-[25px] md:p-[50px] md:col-span-5 lg:col-span-6 h-full',
    {
      'hidden md:block': isMainMenuVisible,
    },
  )

  const {data, status} = useSession()
  const customerContext = useCustomerAffiliateContext()
  const router = useRouter()
  const [maintenance, setMaintenance] =
    useState<MaintenancePageStoryblok | null>(null)
  const [alert, setAlert] = useState<AlertStoryblok | null>(null)

  useEffect(() => {
    if (data && !data.extension_HasAccessToDATS24B2BMobilityPortal) {
      analytics.sendUnauthorizedPageView(
        'unauthorized',
        ['unauthorized'],
        200,
        customerContext.currentUser?.digitalServiceUserId,
        router.locale,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerContext.currentUser?.digitalServiceUserId])

  useEffect(() => {
    const storyblokApi = getStoryblokApi()

    const getAlert = async () => {
      let sbParams = {
        version: 'published' as const,
        resolve_links: 'story' as const,
        language: router.locale ?? 'nl',
      }

      return await storyblokApi.get(`cdn/stories/alerts/alert`, sbParams)
    }

    const getMaintenance = async () => {
      let sbParams = {
        version: 'published' as const,
        resolve_links: 'story' as const,
        language: router.locale ?? 'nl',
      }

      return await storyblokApi.get(
        `cdn/stories/singletons/maintenance`,
        sbParams,
      )
    }

    getAlert()
      .then(result => {
        if (result) {
          setAlert(result.data.story.content)
        }
        console.log('layout alert: ', result)
      })
      .catch(() => {})

    getMaintenance()
      .then(result => {
        if (result) {
          setMaintenance(result.data.story.content)
        }
        console.log('layout maintenance: ', result)
      })
      .catch(() => {})
  }, [router.locale])

  return (
    <>
      {status === 'authenticated' &&
      data?.extension_HasAccessToDATS24B2BMobilityPortal ? (
        <div className="flex w-full flex-col bg-dats-gray-page-bg md:flex-row">
          <aside className="sticky top-0 z-50 size-full md:w-1/5 md:min-w-[202px]">
            {/* On mobile screens we switch between the fullscreen main menu story blok
          component rendered in 'sm' mode and the menu bar that should be visible on
          top of every page. */}
            <nav className="md:hidden">
              {isMainMenuVisible ? (
                <StoryblokComponent
                  blok={mainMenu.content}
                  mainMenuSlugAlternates={mainMenuSlugAlternates}
                  defaultSlug={defaultSlug}
                  translatedSlugs={translatedSlugs}
                  shouldCloseMenuCallback={() => setIsMainMenuVisible(false)}
                />
              ) : (
                <MainMenuHeader
                  blok={mainMenu.content as MainMenuStoryblok}
                  isMainMenuVisible={isMainMenuVisible}
                  shouldOpenMenuCallback={() => setIsMainMenuVisible(true)}
                />
              )}
            </nav>

            {/* On non-mobile screens we display only the normal main menu
          component in 'md' or larger mode. */}
            <nav className="hidden md:block">
              <StoryblokComponent
                blok={mainMenu.content}
                mainMenuSlugAlternates={mainMenuSlugAlternates}
                defaultSlug={defaultSlug}
                translatedSlugs={translatedSlugs}
              />
            </nav>
          </aside>

          <div className="lgmd:max-h-screen flex w-full flex-col justify-between md:overflow-hidden">
            {alert ? <AlertContainer blok={alert} /> : null}
            {maintenance ? <Maintenance blok={maintenance} /> : null}
            <main className={mainClassNames}>{children}</main>
            <StoryblokComponent blok={footer.content} key={footer.uuid} />
          </div>
        </div>
      ) : (
        <AuthError
          image={authPage.content.image}
          text={authPage.content.text}
          title={authPage.content.title}
        />
      )}
    </>
  )
}

export default Layout
