/// This is the header menu bar displayed on mobile pages. From here,
/// users can open the actual fullscreen main menu.
///
/// NOTE: This takes blok info, but is NOT a Storyblok component!

import Image from 'next/image'
import React from 'react'
import {useTranslation} from 'next-i18next'
import {MainMenuStoryblok} from 'types/storyblok-types'

type MainMenuHeader = {
  blok: MainMenuStoryblok
  isMainMenuVisible: boolean
  shouldOpenMenuCallback?: () => void
  shouldCloseMenuCallback?: () => void
}

const MainMenuHeader = ({
  blok,
  isMainMenuVisible,
  shouldOpenMenuCallback,
  shouldCloseMenuCallback,
}: MainMenuHeader) => {
  const {t} = useTranslation('common')

  const openMenuButtonHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    shouldOpenMenuCallback?.()
  }

  const closeMenuButtonHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
    shouldCloseMenuCallback?.()
  }

  return (
    <>
      {isMainMenuVisible ? (
        <>
          <div className="flex h-[50px] items-center justify-between bg-white px-6 md:hidden ">
            <div className="font-ibmPlex font-bold text-dats-green">
              {t('menu_header')}
            </div>
            <button onClick={closeMenuButtonHandler}>
              <Image
                className="h-[21px]"
                src="/images/icon_times.svg"
                alt={t('accessibility_close_menu')}
                width={32}
                height={32}
                unoptimized={true}
              />
            </button>
          </div>
          <div className="flex h-[14px] items-center justify-between bg-white px-6 shadow-md shadow-dats-menu-shadow md:hidden"></div>
        </>
      ) : (
        <div className="flex h-[64px] items-center justify-between bg-white px-6 shadow-lg shadow-dats-menu-shadow md:hidden ">
          <div>
            {blok.logo && (
              <Image
                src={blok.logo.filename}
                alt=""
                width={128}
                height={20}
                unoptimized={true}
              />
            )}
          </div>
          <button onClick={openMenuButtonHandler}>
            <div className="flex space-x-2">
              <span className="font-body font-normal text-dats-green">
                {t('menu_header')}
              </span>
              <Image
                src="/images/icon_bars.svg"
                alt=""
                width={16}
                height={16}
                unoptimized={true}
              />
            </div>
          </button>
        </div>
      )}
    </>
  )
}

export default MainMenuHeader
